import React from 'react'
import { Link } from 'gatsby'
class Header extends React.Component {
  render() {
    return (
      <header className="header-nav header-transparent-white">
        <div className="fixed-header-bar">
          <div className="navbar navbar-default navbar-expand-lg main-navbar">
            <div className="container container-large">
              <div className="navbar-brand">
                <div className="logo-dark">
                  <Link className="logo" to="/">
                    xlPort
                  </Link>
                </div>
                <div className="logo-light">
                  <Link className="logo" to="/">
                    xlPort
                  </Link>
                </div>
              </div>

              {/* <div
                className="navbar-collapse justify-content-end collapse"
                id="navbar-collapse-toggle"
              >
                <ul className="navbar-nav m-auto align-items-lg-center">
                  <li>
                    <Link className="nav-link" to="/docs/">
                      Documentation
                    </Link>
                  </li>
                  <li>
                    <a className="nav-link" href="#contact">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="extra-menu d-flex align-items-center">
                <div className="d-none d-md-inline-block">
                  <a
                    className="m-btn m-btn-md m-btn-theme2nd m-btn-round m-btn-shadow"
                    href="mailto:peter@postxl.com"
                  >
                    Get Started
                  </a>
                </div>
                <button
                  type="button"
                  className="navbar-toggler collapsed"
                  data-toggle="collapse"
                  data-target="#navbar-collapse-toggle"
                  aria-expanded="false"
                >
                  <span className="icon-bar"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
