import React from 'react'
import { Link } from 'gatsby'

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-one">
        <div className="footer-top">
          <div className="container">
            <div className="row" style={{ justifyContent: 'center' }}>
              {/* <div className="col-lg-3 col-sm-6 m-15px-tb"> */}
              <div className="footer-about text-center">
                <Link className="mx-3" to="/">
                  xlPort
                </Link>
                <Link className="mx-3" to="/terms-of-use">
                  Terms of Use
                </Link>
                <Link className="mx-3" to="/impressum">
                  Impressum
                </Link>
                <Link className="mx-3" to="/datenschutz">
                  Datenschutz
                </Link>
              </div>
              {/* </div> */}
              {/* <div className="col-lg-3 col-sm-6 m-15px-tb">
                <div className="footer-link">
                  <h5 className="fot-title">Links</h5>
                  <ul className="nav flex-column">
                    <li>
                      <Link to="/docs/">Documentation</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container" style={{ textAlign: 'center' }}>
            <p>Copyright © {new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
