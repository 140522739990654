import React from 'react'
import '../assets/plugin/bootstrap/css/bootstrap.min.css'
import '../assets/plugin/font-awesome/css/all.min.css'
import '../assets/plugin/et-line/style.css'
import '../assets/plugin/themify-icons/themify-icons.css'
import '../assets/plugin/owl-carousel/css/owl.carousel.css'
import '../assets/plugin/magnific/magnific-popup.css'
import '../assets/style/master.scss'

import Header from './Header'
import Footer from './Footer'

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: 'is-loading',
    }
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
    }, 100)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  render() {
    const { children } = this.props

    return (
      <main className={`body ${this.state.loading}`}>
        <Header />
        {children}
        <Footer />
      </main>
    )
  }
}

export default Template
